#Login-Contenedor-Formulario{
    width: 100%;
    height: 100vh;
    position: absolute;
    background: rgba(23, 61, 129, 0.46);
    display: flex;
    place-content: center;
}

#Login-Formulario-Fondo{
    width: 100%;
    height: 100vh;
}

#Login-Formulario-Capa{
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(24, 118, 242, 0.45);
}

#Login-Formulario{
    margin:0;
    padding: 0;
    display: flex;
    align-items: center;
    place-content: center;

    position: absolute;
    /* background-color: red; */
    width: 40%;
    height: 100vh;
}
@media screen and (max-width: 425px) {
    #Login-Formulario{
        margin:0;
        padding: 0;
        display: flex;
        align-items: center;
        place-content: center;
        top: 45px;
        position: absolute;
        /* background-color: red; */
        width: 40%;
        height: 100vh;
    }
}
#Login-Formulario-Titulo{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
}

#Login-Formulario-Texto{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
}

@media screen and (max-width: 425px) {
    #Login-Formulario-Texto{
        font-family: Segoe UI;
        font-style: normal;
        font-weight: bold;
        margin-left: 30px !important;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
    }
}



.Login-Formulario-Input{
    width: 299px;
    height: 47px;
    background: #FFFFFF;
    border: 0;
    padding-left: 10px;

    font-family: Segoe UI;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

.Login-Formulario-Input:focus{
    border: none;
    outline: none;
}

#Login-Formulario-Input{
    width: 299px;
    height: 47px;
    background: #FFFFFF;
    border: 0;
    padding-left: 10px;

    font-family: Segoe UI;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

#Login-Formulario-Input:focus{
    border: none;
    outline: none;
}

#Login-Formulario-Input-Password{
    width: 271px;
    height: 30px;
    background: #FFFFFF;
    border: 0;
    /* padding-left: 10px; */

    font-family: Segoe UI;
    font-style: normal;
    font-size: 18px;
    line-height: 21px;
}

#Login-Formulario-Btn-Iniciar{
    margin-top: 20px;
    width: 299px;
    height: 47px;
    background: #1956C5;
    display: flex;
    align-items: center;
    place-content: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    /* cursor: pointer; */
    border: none;
    outline: none;
}

#Login-Formulario-Btn-Desactivado-Iniciar{
    margin-top: 20px;
    width: 299px;
    height: 47px;
    background: #A4A3A3;
    display: flex;
    align-items: center;
    place-content: center;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    /* cursor: pointer; */
    border: none;
    outline: none;
}

#Login-Formulario-Btn-Iniciar:focus{
    border: none;
    outline: none;
}

#Subtitulo-Formulario-Titulo{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-top: -20px;
    margin-bottom: 40px;
}

#Contenedor-Logos-Paises{
    position: absolute;
    top: 50px;
    right: 40px;
}
@media screen and (max-width: 425px) {
    #Contenedor-Logos-Paises{
        position: absolute;
        top: 70px;
        right: 10px;
    }
  }

#Bandera-Pais-Login{
    width: 40px;
    margin-right: 10px;
}
@media screen and (max-width: 375px) {
    #Bandera-Pais-Login{
        width: 32px;
        margin-right: 10px;
    }
}

#Contenedor-Logo-Grow-Login{
    position: absolute;
    top: 50px;
    left: 40px;
}
@media screen and (max-width: 425px) {
    #Contenedor-Logo-Grow-Login{
        position: absolute;
        top: 55px;
        left: 40px;
    }
}
@media screen and (max-width: 320px) {
    #Contenedor-Logo-Grow-Login{
        position: absolute;
        top: 55px;
        left: 0px;
    }
}

#Logo-Grow-Blanco-Negro-Login{
    width: 250px !important;
    margin-bottom: 20px;
}


#FullScreen-Video{
        object-fit: cover;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
}


#Txt-Bienvenida-Login{
    text-align: left !important;

    /* font-family: Segoe UI; */
    /* font-style: normal; */
    /* font-weight: bold; */
    /* font-size: 40px; */
    /* line-height: 42px; */
    /* text-align: center; */
    /* color: #ffffff; */

    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 31px;

    color: #FFFFFF;
}

.Txt-Consumo-Masivo{
    font-family: Segoe UI;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 31px;

    color: #FFFFFF;
    /* background: #1876F2;
    mix-blend-mode: multiply; */
    background: rgba(6, 44, 180, 0.65);
    padding-left: 5px;
    padding-right: 5px;
    /* padding-top: -100px; */
}